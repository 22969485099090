:root {
  --main-bg-color: #fbf9f7;
  --body-padding: 80px;
  --primary-color: black;
  --header-height: 80px;
  --footer-height: 80px;
  --accent-color: #10069f;
  --body-text {
    font-size: 16px;
    font-weight: 300;
  }
}
