body {
  font-family: "Space Grotesk", sans-serif;
  margin: 0;
  box-sizing: border-box;
  color: var(--primary-color);
  min-width: 320px;
  background-color: var(--main-bg-color);
}

.wrapper {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

.content {
  flex-grow: 1; /* Pushes footer down */
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: left;
  justify-content: center;
}

.homeContainer {
  min-height: 70vh;
  display: flex;
  flex-direction: column;
  justify-content: center; /* vertical centering */
  align-items: center;
  text-align: center;
  gap: 2vw;
  box-sizing: border-box;
}

.homeContainer p {
  font-size: 1rem;
  line-height: 1.6;
  max-width: 800px;
  margin-bottom: 15px;
}

.accent {
  color: var(--accent-color);
  display: inline-block;
  opacity: 0;
  animation: dotEnter 1.7s ease-out forwards,
    dotFloat 5s cubic-bezier(0.65, 0, 0.35, 1) infinite;
  animation-delay: 0s, 1.7s;
}

.homepageCTA {
  border: solid 0.5px #cacaca;
  width: 210px;
  height: 70px;
  font-size: 18px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
}

@media (max-width: 768px) {
  .headline1 {
    font-size: 2.25rem;
  }

  .homeContainer {
    padding: 8vh 6vw 8vh 6vw;
    gap: 6vw;
  }

  .homepageCTA {
    font-size: 0.95rem;
    padding: 10px 20px;
  }
}

.homepageCTA:hover {
  color: white;
  background-color: black;
}

.homepageWorkSection {
  margin-top: 100vh;
  padding-top: 4rem;
  min-height: 85vh;
}

@keyframes dotEnter {
  from {
    opacity: 0;
    transform: translateY(-100px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

/* Gentle float */
@keyframes dotFloat {
  0%,
  100% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-5px); /* small movement = smoother */
  }
}

.fadeSlideIn {
  opacity: 0;
  transform: translateY(20px);
  animation: fadeSlideUp 2s ease-out forwards;
}

@keyframes fadeSlideUp {
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.link {
  color: #10069f;
  font-weight: 700;
  text-decoration: underline;
}
