@import url("https://fonts.googleapis.com/css2?family=Space+Grotesk:wght@300..700&display=swap");

.container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  margin: 0 auto;
  box-sizing: border-box;
  padding: 5vw;
}

/* Brand name */
.name {
  font-size: 28px;
  font-weight: 500;
  text-decoration: none;
  color: var(--primary-color);
  white-space: nowrap;
}

/* Menu item shared styles */
.menuItem {
  display: block;
  text-decoration: none;
  color: var(--primary-color);
  cursor: pointer;
}

.menuItem .text {
  font-size: 18px;
  font-weight: 400;
  white-space: nowrap;
}

.menuItem.active .text {
  color: var(--accent-color);
  font-weight: 600;
}

/* ---------- Desktop Menu ---------- */
.desktopMenu {
  display: flex;
  gap: 6vw;
}

@media (max-width: 768px) {
  .desktopMenu {
    display: none;
  }
}

/* ---------- Burger Icon ---------- */
.burger {
  display: none;
  flex-direction: column;
  justify-content: space-between;
  height: 20px;
  width: 28px;
  cursor: pointer;
  z-index: 20;
}

.burger span {
  height: 2px;
  width: 100%;
  background-color: var(--accent-color);
  border-radius: 1px;
  transition: all 0.3s ease-in-out;
}

.burger.open span:nth-child(1) {
  transform: rotate(45deg) translateY(8px);
}

.burger.open span:nth-child(2) {
  opacity: 0;
}

.burger.open span:nth-child(3) {
  transform: rotate(-45deg) translateY(-8px);
}

/* ---------- Mobile Sidebar Menu ---------- */
.sidebarMenu {
  position: fixed;
  top: 0;
  right: -100%;
  width: 66%;
  max-width: 320px;
  height: 100vh;
  background-color: white;
  box-shadow: -2px 0 10px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  padding: 100px 30px;
  transition: right 0.3s ease-in-out;
  z-index: 10;
}

.sidebarMenu.open {
  right: 0;
}

.sidebarMenu .menuItem {
  padding: 26px 42px;
  border-bottom: solid 0.5px #eaeaea;
  margin-left: -30px;
  margin-right: -30px;
}

.menuItem:hover .text {
  background-color: #f2f4ff;
  font-weight: 600;
}

/* Hide sidebar on desktop */
@media (min-width: 769px) {
  .sidebarMenu {
    display: none !important;
  }
}

/* Overlay (optional) */
.menuOverlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 5;
  backdrop-filter: blur(1px);
  transition: opacity 0.3s ease-in-out;
}

/* Show burger only on mobile */
@media (max-width: 768px) {
  .burger {
    display: flex;
  }
  .name {
    font-size: 20px;
  }
}
