.footer {
  padding: 40px 5vw;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  font-size: 0.875rem;
  color: var(--primary-color);
  opacity: 0.5;
  border-top: 1px solid #eaeaea;
}

.footer span {
  font-weight: 500;
}

@media (max-width: 768px) {
  .footer {
    font-size: 0.75rem;
    padding: 30px 5vw;
  }
}

@media (max-width: 480px) {
  .footer {
    font-size: 0.7rem;
    padding: 24px 5vw;
  }
}
