.leftText {
  text-align: left;
  max-width: 60ch;
  margin: 0 auto;
  line-height: 1.75;
}

.aboutPhotoWrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  margin-top: 3rem;
  gap: 0%;
}

.aboutContainer {
  padding-bottom: 5vw;
}

.aboutPhotoEnd {
  width: 180px;
  height: 180px;
  object-fit: cover;
  border-radius: 9999px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.08);
}

@media (max-width: 768px) {
  .aboutPhotoWrapper {
    flex-direction: column;
    align-items: center;
    text-align: center;
    gap: 1rem;
    margin-top: 2.5rem;
  }

  .aboutPhotoEnd {
    margin-top: 1.5rem;
  }
}
