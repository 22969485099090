.play-page {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 5vw;
  text-align: center;
}

.headline1 {
  font-size: 4rem;
  color: var(--primary-color);
  font-weight: 500;
}

.leftText {
  max-width: 60ch;
  font-size: 1rem;
  line-height: 1.6;
  margin-top: 1.5rem;
  text-align: left;
}

/* Grid layout for images */
.gallery-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(220px, 1fr));
  gap: 1.5rem;
  width: 100%;
  max-width: 1000px;
  margin-top: 2.5rem;
}

.gallery-item {
  position: relative;
  overflow: hidden;
  border-radius: 10px;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  cursor: pointer;
}

.gallery-item:hover {
  transform: scale(1.03);
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.08);
}

.painting-image {
  width: 100%;
  height: auto;
  object-fit: cover;
  display: block;
  max-height: 260px;
  border-radius: 10px;
}

/* Lightbox */
.lightbox {
  display: flex;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.8);
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.lightbox img {
  max-width: 90%;
  max-height: 90%;
  border-radius: 10px;
}

/* Medium screens */
@media (max-width: 768px) {
  .headline1 {
    font-size: 3.5rem;
  }

  .leftText {
    line-height: 1.5;
    text-align: center;
  }

  .gallery-grid {
    grid-template-columns: repeat(auto-fit, minmax(140px, 1fr));
    gap: 1rem;
  }

  .painting-image {
    border-radius: 8px;
    max-height: 200px;
  }
}

/* Small screens */
@media (max-width: 480px) {
  .play-page {
    padding: 5vw 4vw;
  }

  .headline1 {
    font-size: 2.95rem;
    /* margin-bottom: 1.25rem; */
  }

  .leftText {
    margin-top: 1rem;
    text-align: center;
  }

  .gallery-grid {
    grid-template-columns: 1fr 1fr;
    gap: 0.75rem;
  }

  .painting-image {
    max-height: 234px;
  }
}
